/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    br: "br"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LandingPageCta, SideBySide} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/types/binaural/'], ['en', 'https://www.hear.com/hearing-aids/types/binaural/'], ['en-US', 'https://www.hear.com/hearing-aids/types/binaural/'], ['en-CA', 'https://ca.hear.com/hearing-aids/types/binaural/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "improved-hearing-at-last",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#improved-hearing-at-last",
    "aria-label": "improved hearing at last permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Improved hearing at last!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "Hearing aids are technologically advanced"), " assistive devices that are on the same level as modern computers. Those old-looking devices with outdated technology have come and gone. ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "Today’s hearing aids"), " are ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-technology/hearing-aids-vs-hearing-amplifiers/",
    className: "c-md-a"
  }, "far more than simple audio amplifiers"), "; they now come loaded with a number of special features that enhance performance and usability."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Below, you can read about what binaural hearing aids offer and how they can make your listening experience much more pleasant. Of course, our hearing aid experts are available to help you with any questions related to hearing loss – free of charge and without obligation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-the-differences-between-old-and-new-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-the-differences-between-old-and-new-hearing-aids",
    "aria-label": "what are the differences between old and new hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are the differences between old and new hearing aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Outdated hearing aid models – individually and independent of each other – amplify sounds. However, modern binaural hearing systems, similar to natural hearing, couple the left and right hearing aids together via a radio signal. The devices are able to intelligently communicate and coordinate with each other. The important thing to remember is that hearing aids are medical devices and most people suffer from bilateral hearing loss so they should get a hearing device for each ear rather just one device. Just like if you had blurry vision, you wouldn’t wear one eye glass, similarly hearing loss should be treated binaurally."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "binaural-hearing-aids-vs-monaural-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#binaural-hearing-aids-vs-monaural-hearing-aids",
    "aria-label": "binaural hearing aids vs monaural hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Binaural hearing aids v/s Monaural hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids have been around for a long time but with so many advances in technology, there are so many options available for hearing systems. When the devices were first manufactured, they were monaural devices. That means that the devices worked individually and if a person was wearing two devices they wouldn’t connect to give an overall, surrounding sound. This meant the wearer could feel quite overwhelmed with all the different information being passed to the ear. However, the latest technology of binaural aids mean that the devices in the two ears are connected to give an overall sound. This ensures that the wearer is able to understand the surroundings without being overloaded with too many different sounds."), "\n", React.createElement(LandingPageCta, {
    copy: "Test hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "binaural-hearing-aids-make-hearing-even-more-natural",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#binaural-hearing-aids-make-hearing-even-more-natural",
    "aria-label": "binaural hearing aids make hearing even more natural permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Binaural hearing aids make hearing even more natural"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Due to the signals from both ears, the auditory center in the brain manages to adequately filter speech from sound and suppress background noises. In this way, speech intelligibility is also significantly improved, even in acoustically demanding situations. Do you feel uncomfortable when you are at a restaurant, in a full train, or in crowded and noisy public places, because you feel you are unable to understand the people with whom you are speaking? We can change that with the help of a binaural hearing system."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-independent-of-your-line-of-sight",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-independent-of-your-line-of-sight",
    "aria-label": "hearing independent of your line of sight permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing independent of your line of sight"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The direction of the microphone normally corresponds with the hearing aid wearer’s line of sight. But if you are sitting at your personal computer and concentrating on the screen, and still want to converse with a person behind you, the older hearing systems will not be of much use. Modern binaural hearing aids, on the other hand, are able to rotate the microphones in the direction from which the speech signal originates, allowing you to have conversations without having to make eye contact. Effortless hearing is now possible with the latest and advanced hearing devices."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "localization",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#localization",
    "aria-label": "localization permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Localization"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Orientation in a room and problem-free directional hearing essentially requires two healthy ears. The auditory center recognizes the tiniest differences between the incoming signals in the left and right ear, and allocates incoming signals to a position in the room (head shadow effect). Binaural signal processing also makes this possible with hearing aids, therefore enabling you to adequately assess direction. Hearing is also connected with balance so it is important that you wear hearing devices for both ears so your brain is able to understand the surroundings and help you balance."), "\n", React.createElement(LandingPageCta, {
    copy: "Test hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "binaural-hearing-aids-cost",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#binaural-hearing-aids-cost",
    "aria-label": "binaural hearing aids cost permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Binaural hearing aids cost"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/prices/",
    className: "c-md-a"
  }, "The cost of hearing devices"), " is hard to approximate as the market has hundreds of different options and the price varies depending on the technology and the features that a particular model has. Some devices are premium level technology with the ability to connect with phones, offering ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/rechargeable/",
    className: "c-md-a"
  }, "a device chargeability function"), ", tinnitus maskers and they are so small that they can’t be seen. Such devices cost a little more than the basic devices which just help with hearing loss and can be manual rather than digital. Binaural hearing aid are advanced level devices but their quick success has meant that ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "different manufacturers have produced different models"), ", giving people a choice of models and prices."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "first-time-wearers-and-binaural-devices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#first-time-wearers-and-binaural-devices",
    "aria-label": "first time wearers and binaural devices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "First-time wearers and binaural devices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "First time users feel more comfortable with one aid because they are being exposed to sounds that they were missing out on before and can feel overwhelmed when they start experiencing all the different sounds. Also their ear can get uncomfortable with wearing hearing aids, this is because they may be wearing small devices but it is still a foreign object that they have to wear throughout the day to ensure they get used to it. However, audiologists recommend that if you need hearing devices for both ears then you should start off with both devices. Over time, you would become comfortable with the sounds and feel less overwhelmed when the two devices connect to give an overall sound experience and better sound directionality compared to one standalone device."), "\n", React.createElement(LandingPageCta, {
    copy: "Test hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "learn-more-about-hearing-aid-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#learn-more-about-hearing-aid-technology",
    "aria-label": "learn more about hearing aid technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Learn More About Hearing Aid Technology"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/phonak-virto.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "cros-and-bicros-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cros-and-bicros-hearing-aids",
    "aria-label": "cros and bicros hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "CROS and BiCROS Hearing Aids"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "CROS hearing aids consist of two in-the-ear devices: a microphone worn in the ear with hearing loss and a receiver that is worn in the functioning ear. The microphone picks up sound from the side with no hearing and sends it to the receiver in the functional ear. BiCROS hearing aids (bilateral microphones with contralateral routing of signal) are designed for people who have bilateral hearing loss, or hearing loss in both ears\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.a, {
    href: "/hearing-aids/types/cros-bicros/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/behind-the-ear-oticon.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "behind-the-ear-hearing-aids-bte",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#behind-the-ear-hearing-aids-bte",
    "aria-label": "behind the ear hearing aids bte permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Behind-the-ear hearing aids (BTE)"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Behind-the-ear hearing aids (BTE) are the best known and most frequently selected models. The hearing aids are very small, light, and can be worn almost invisibly behind the ear. Behind-the-ear hearing aids are suitable for almost all types of hearing loss.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/in-the-ear-hearing-aids.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "in-the-ear-hearing-aids-ite",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-the-ear-hearing-aids-ite",
    "aria-label": "in the ear hearing aids ite permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In-the-ear hearing aids (ITE)"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "In-the-Ear hearing aids come in two custom-made and individually-fitted styles. The less obvious of the two ITEs rest in the lower part of your ear – the part that resembles half of a shell.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "Read more"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
